@import "./elements";
@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'), url(../assets/fonts/Avenir_Next/AvenirNext-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Regular'), url(../assets/fonts/Avenir_Next/AvenirNext-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Medium'), url(../assets/fonts/Avenir_Next/AvenirNext-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Bold'), url(../assets/fonts/Avenir_Next/AvenirNext-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next ExtraLight'), url(../assets/fonts/Avenir_Next/AvenirNext-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next Black'), url(../assets/fonts/Avenir_Next/AvenirNext-Black.ttf) format('truetype');
  font-weight: 900;
}
body {
  font-size: 14px;
  background-color: $body-bg-color;
  font-family: $primary-font-family;
  font-family: Avenir Next;
  color: #202B5B;
}

span {
  font-family: Avenir Next;
}

.p-1 { padding: 4px}
.p-2 { padding: 8px}
.p-4 { padding: 16px}
.p-8 { padding: 32px}
.p-12 { padding: 48px}
.p-16 { padding: 64px}

.mb-1 { margin-bottom: 4px}
.mb-2 { margin-bottom: 8px}
.mb-4 { margin-bottom: 16px}
.mb-8 { margin-bottom: 32px}
.mb-16 { margin-bottom: 64px}

.mt-1 { margin-top: 4px}
.mt-2 { margin-top: 8px}
.mt-4 { margin-top: 16px}
.mt-8 { margin-top: 32px}
.mt-16 { margin-top: 64px}

.mlp-25 { margin-left: 25% }

.title-text {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-title;
}
.title-text-md {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-title-md;
}

.ant-btn-primary {
  background: $primary-button-color;
}

.ant-btn-lg {  height: 55px; }

.ant-input-affix-wrapper > input.ant-input {
  height: 40px;
  padding: 20px 15px;
  border-radius: 7px;
}

.ant-btn-link {
  color: $primary-color;
}

.ant-input-affix-wrapper {
  height: 50px;
  border-radius: 7px;
}

.ant-input {
  height: 50px;
  padding: 20px 25px;
  border-radius: 7px;
}
.ant-avatar {
  background-color: #ccc0;
}
.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
}

.ant-btn:hover {
  border-color: #202b5b !important;
  background-color: #202b5b !important;
  color: white !important;
}

.upload-area:hover {
  border-color: #202b5b !important;
  color: white !important;
}

.ant-input:hover {
  border-color: #202b5b !important;
}
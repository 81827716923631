@import "../../styles/elements";

.request-modal .success-modal-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    padding: 10px;

    .result-icon {
        font-size: 100px;
        margin-bottom: 20px;

        &.success {
            color: #9D377D;
        }
    }

    p {
        font-size: 16px;
        font-family: $primary-font-family;
    }
}

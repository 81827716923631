@import "../../styles/elements";

.failure-modal .ant-modal-content {
    padding: 20px;
    border-radius: 0px;
}

.failure-modal-content {
    display: flex;
    flex-direction: column;
}

.failure-modal-content p {
    margin-bottom: 16px;
    margin-top: 20px;
    font-size: 14px;
    font-family: $primary-font-family;
    text-align: left;
}

.search-button {
    margin-bottom: 8px;
    border-radius: 0px;
    background-color: #202b5b;
    padding-top: 13px;
    padding-bottom: 40px; 
    font-size: 18px;
    color: white;

    &:hover {
        background-color: #202b5b !important;
        color: white !important;
    }
}

.time-slot-button {
    width: 100px;
    height: 40px;
    color: #202B5B;
    border-radius: 0px;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #202B5B;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.time-slot-button.selected {
    background-color: #9D377D;
    color: white;
    border: none;
}

.time-slot-button.disabled {
    background-color: grey;
    cursor: not-allowed;
    border: none;
}
.custom-radio-group {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .custom-radio-label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .custom-radio-input {
    display: none;
  }
  
  .custom-radio-button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    position: relative;
    background-color: #9A9B9C;
  }
  
  .custom-radio-input:checked + .custom-radio-button {
    border-color: transparent;
  }
  
  .custom-radio-input:checked + .custom-radio-button::after {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: currentColor;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .custom-radio-text {
    font-size: 16px;
    color: #202B5B;
    font-weight: 600;
  }
  
  .available {
    color: #202B5B;
  }
  
  .limited {
    color: #202B5B;
  }
  
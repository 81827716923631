@import "../../styles/elements";

.booking-result-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;
    min-height: 100vh;
    padding-bottom: 60px;
    overflow: hidden;
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../../assets/images/background.png') no-repeat center center fixed;
      background-size: cover;
      opacity: 0.2; 
      z-index: -1; 
    }
    
  .header {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .logo {
      width: 50px;
      margin-right: 0px;
    }
  }

  .result-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;

    .result-icon {
      font-size: 100px;
      margin-bottom: 20px;

      &.success {
        color: #9D377D;
      }

      &.error {
        color: #9D377D;
      }
    }

    .result-title {
      font-size: 24px;
      margin-bottom: 20px;
      line-height: 23px;
    }

    .result-message {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .appointment-details {
      font-size: 18px;
      font-weight: 500;
      margin: 30px;
    }

    .retry-button {
      margin-top: 20px;
      font-size: 20px;
      padding-top: 15px;
      padding-bottom: 45px;
      font-weight: 500;
      width: 100%;
      border-radius: 0;
      border: 2px solid #202B5B;
    }

    .contact-button {
      margin-top: 20px;
      font-size: 20px;
      padding-top: 15px;
      padding-bottom: 45px;
      font-weight: 500;
      width: 100%;
      border-radius: 0;
      border: 2px solid #202B5B;
    }

    .contact-number {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
}

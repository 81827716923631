@import "../../styles/elements";

.availability-calendar {
    background-color: white;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.normal-header {
    margin-bottom: 10px;
}

.title-container {
    margin-bottom: 10px;
}

.duration-text {
    font-size: 16px;
    text-align: center;
    padding: 20px 0;
    padding-bottom: 10px;
}

.availability-options {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.request-further-text {
    color: #9D377D !important;
    text-decoration: underline !important;
    background-color: white;
    font-size: 18px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
}

.request-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.week-days-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
}

.week-day-container {
    width: 32px;
    display: flex;
    justify-content: center;
}

.week-day-text {
    font-size: 12px;
}
.calendar-container {
    padding: 10px;

    .weekdays {
        display: flex;

        .weekday {
            text-align: center;
            font-weight: 500;
            padding: 5px;
        }
    }

    .month-header {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        margin: 20px 10px;
    }

    .week-row {
        display: flex;

        .day-cell {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            width: 40px;
            height: 40px;

            &.selected,
            &.today {
                border-radius: 50%;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &.selected {
                background-color: #9D377D;
                color: white;
            }

            &.today {
                color: #202B5B;
                border: 2px solid #9D377D;
            }

            &.available {
                color: #202B5B;
            }

            &.unavailable {
                text-decoration: line-through;
                color: lightgray;
            }

            &.empty {
                visibility: hidden; 
                height: 40px;
                width: 40px;
            }

            span {
                visibility: visible;

                &.hidden {
                    visibility: hidden;
                }
            }
        }
    }
}

@import "../../styles/elements";

.attach-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;
    min-height: 100vh;
    padding-bottom: 60px;
    overflow: hidden;
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../../assets/images/background.png') no-repeat center center fixed;
      background-size: cover;
      opacity: 0.2; 
      z-index: -1; 
    }

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
    align-items: center;

    .logo {
      width: 50px;
      margin-right: 0px;
    }
    .back-link {
      cursor: pointer;
    }
  }

  .progress-indicator {
    width: 100%;
  }

  .booking-info {
    text-align: justify;
    margin-bottom: 20px;
  }

  .ant-upload-wrapper .ant-upload-select {
    width: 100%;
  }

  .document-container {
    max-width: 600px;
    .document-upload-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
  
      .document-upload {
        width: 100%;
  
        .upload-area {
          width: 100%;
          text-align: center;
          border: 2px dashed #d9d9d9;
          background-color: #F4F4F7;
          padding: 30px 70px;
          border-radius: 8px;
          position: relative;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          transition: border-color 0.3s;
  
          &:hover {
            border-color: #40a9ff;
          }
  
          .form-upload {
            font-weight: 600;
            font-size: 18px;
          }
  
          .upload-description {
            margin-top: 10px;
            color: #666;
          }
        }
      }
    }
  }

  .notes {
    width: 100%;
    margin-bottom: 20px;

    .notes-title {
      font-size: 16px;
      font-weight: 500;
      color: #202B5B;
      margin-bottom: 10px;
    }

    .ant-input {
      width: 100%;
      height: 145px;
      font-family: $primary-font-family;
    }
  }

  .next-button {
    font-size: 18px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 45px;
    border-radius: 0;
    margin-top: 10px;
  }
}

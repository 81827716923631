$color-white: #ffffff;
$color-green: #006233;
$color-silver-sand: #BABBBC;
$color-sky-blue: #20AAE4;
$color-loght-sky-blue: #EEF6FF;
$color-welbeck-grey: #D5D6D2;
$color-welbeck-purple: #9D377D;
$color-welbeck-blue: #202B5B;
$color-welbeck-dark-grey : #9A9B9C;
// color
$primary-color: $color-welbeck-blue;
$header-bg-color: $color-white;
$body-bg-color: $color-white;
$primary-button-color: $color-welbeck-blue;
// Service Colours
$digestive-health: #a1a466;
$ear-nose-and-throat: #8e2c48;
$imaging-and-diagnosis: #98d9ff;
$neurology: #92b8ff;
$endoscopy: #d86e2c;
$endocrinology: #c12121;
$heart-health: #5c2244;
$lung-health: #ff6f61;
$orthopaedics: #004438;
$womens-health: #beb2d5;
$skin-health-and-allergy: #a2e8db;

// font
$primary-font-family: 'Avenir Next';
$font-size-title: 30px;
$font-size-title-md: 25px;
.group-container {
    margin-bottom: 20px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.slot-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

p {
    font-size: 16px;
}
@import "../../styles/elements";

.request-modal .ant-modal-content {
    padding: 24px;
    border-radius: 0px;
  }
  
  .request-modal-content {
    display: flex;
    flex-direction: column;
  }
  
  .request-modal-content p {
    margin-bottom: 16px;
    margin-top: 20px;
    font-size: 14px;
    font-family: $primary-font-family;
    text-align: left;
  }
  
  .input-field {
    margin-bottom: 16px;
    border-radius: 0px !important;

    &::placeholder {
        font-family: $primary-font-family;
    }
  }

  .input-label {
    font-size: 14px;
    font-family: $primary-font-family;
    color: #202b5b;
    margin-bottom: 8px;
    font-weight: 500;
  }

  .request-button {
    margin-bottom: 8px;
    border-radius: 0px;
    background-color: #202b5b;
    padding-top: 10px;
    padding-bottom: 40px;
    font-size: 18px;
    color: white;

    &:hover {
      background-color: #202b5b !important;
      color: white !important;
    }
  }

  .request-modal-content-text {
    font-size: 14px;
    font-family: $primary-font-family;
    color: #202b5b;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  .call-button {
    border-radius: 0px;
    padding-top: 10px;
    padding-bottom: 40px;
    font-size: 18px;
    border-width: 2px;
    border-color: #202b5b;
    color: #202b5b;

    &:hover {
        border-color: #202b5b !important;
        color: #202b5b !important;
    }
  }
  
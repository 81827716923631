.progress-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px; 
}

.progress-bar {
    display: flex;
    align-items: center;
    width: 100%; 
    max-width: 800px; 
    position: relative;

    .progress-line {
        flex: 1;
        height: 4px; 
        background-color: #d3d3d3; 
        position: relative;
        margin: 0 5px; 

        &.completed {
            background-color: #8b3a62; 
        }
    }

    .progress-point {
        width: 12px; 
        height: 12px; 
        background-color: #d3d3d3; 
        border-radius: 50%;
        position: relative;
        z-index: 1;

        &.start {
            &.completed {
                background-color: #8b3a62; 
            }
        }

        &.middle {
            &.completed {
                background-color: #8b3a62; 
            }
        }

        &.end {
            &.completed {
                background-color: #8b3a62; 
            }
        }
    }
}

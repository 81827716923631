@import "../../styles/elements";

.bookings-wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $body-bg-color;
}
.bookings-info {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.booking-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background: url("../../assets/images/booking.png") no-repeat;
  background-size: cover;
  flex-shrink: 0;
  padding: 24px 40px 34px;
  z-index: 2;
}

.booking-content {
  width: 100%;
  margin: 0 auto;
}

.begin-button {
  background-color: #202b5b;
  font-size: 20px;
  padding-top: 15px;
  padding-bottom: 45px;
  border-radius: 0;
  width: 100%;
}

.booking-title {
  font-size: 24px;
  margin-bottom: 16px;
}

.booking-description {
  font-size: 16px;
  margin-bottom: 16px;
}

@media only screen and (min-width: 768px) {
  .bookings-wrap {
    flex-direction: row;
    height: 100vh;
  }
  .bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .bookings-info {
    max-width: 50vw;
    overflow: hidden;
  }
}

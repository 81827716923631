$purple: #9D377D;
$welbeckBlue: #202B5B;
$white: #ffffff;
$black: black;
$welbeckDarkGrey: #6e6e6e;

.accordion-container {
  width: 100%;
  align-self: center;
}

.accordion {
  background-color: $white;
  border-radius: 2px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.12);
  margin: 10px 10px;
  transition: background-color 0.5s ease;

  &.accordion-no-shadow {
    border-bottom: 1px solid $welbeckDarkGrey;
    box-shadow: none;
  }

  &.no-border-bottom {
    border-bottom-width: 0;
  }

  &.background-purple {
    background-color: $purple;
  }

  .accordion-header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    cursor: pointer;
    border-radius: 7px;
    transition: background-color 0.5s ease;

    .accordion-icon {
      margin-top: 10px;
    }
  }

  .accordion-row {
    width: 100%;
    align-self: center;
  }

  .accordion-content-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease 0.2s;

    &.expanded {
      max-height: 1000px; 
    }
  }

  .accordion-content {
    padding: 0 15px 15px 15px;
    transition: opacity 0.5s ease 0.2s;
  }
}
